.ask-card {

  position:absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 32px;

  @media screen and ($media-mobile) {
    padding: 0 16px;
    bottom: 16px;
  }

  p, ul, li {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  &--social {
    p {
      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }
    }

    a {
      font: 16px/34px 'RF Devi Semi Bold', sans-serif;
      color: $color-blue-dark;
      text-decoration: underline;
      display: inline-block;
      height: 34px;
      position: relative;
      margin: 0 16px 0 42px;


      &:after {
        content: '';
        height: 9px;
        width: 9px;
        position: absolute;
        top: 14px;
        right: -14px;
        background: transparent url(assets/images/arrow-link.svg) center / cover no-repeat;
        transform-origin: center;
        transform: rotate(-45deg);
        opacity: .5;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -42px;
        height: 34px;
        width: 34px;
      }
    }

    .vkontakte-link:before {
      background: transparent url(assets/images/vk.svg) center / cover no-repeat;
    }

    .odnoklassniki-link:before {
      background: transparent url(assets/images/ok.svg) center / cover no-repeat;
    }
  }

  .phone {
    font: 24px/24px 'RF Devi Semi Bold', sans-serif;
    color: $color-blue-dark;
    letter-spacing: -0.24px;;
    text-decoration: underline;
    margin: 0 0 22px;
    display: block;
  }

  .sub-text {
    font: 14px/20px 'RF Devi Semi Bold', sans-serif;
    color: rgba($color-blue-dark, .5);

    a {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .application-links {
    display: flex;
    flex-flow: row wrap;
    margin: 32px 0 0;

    li {
      font: 16px/24px 'RF Devi Semi Bold', sans-serif;
      color: $color-blue-dark;
      margin: 0 18px 0 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 11px;
        right: -9px;
        height: 2px;
        width: 2px;
        background: $color-blue-dark;
      }

      &:last-child {
        margin: 0;
      }

      &:last-child:before {
        content: none;
      }
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .arrow-link {
    display: block;
    height: 24px;
    width: 24px;
    background: transparent url(assets/images/arrow-link.svg) center / cover no-repeat;
  }

}
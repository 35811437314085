
@import "../assets/scss/config";

.mobile-control {
	display: none;

	@media screen and ($media-mobile) {
		display: block;
		position: relative;
		z-index: 2;
	}

	&__anons {
		font: 16px/24px 'RF Devi Semi Bold', sans-serif;
		margin: 58px 16px 26px;
	}

	&__list {
		margin: 0 16px 256px;
		padding: 0;
		list-style: none;
	}

	&__item {
		margin: 0 0 4px;

		&:last-child {
			margin: 0;
		}
	}

	&__button {
		-webkit-appearance: none;
		text-decoration: none;
		background: $color-red;
		font: 16px/48px 'RF Devi Semi Bold', sans-serif;
		color: $color-white;
		text-align: center;
		display: block;
		border-radius: 8px;
	}
}


@use "sass:math";
@import "../assets/scss/config";

.ask-question {

	&--desktop {
	}

	&--mobile {
		display: none;

		@media screen and ($media-mobile) {
			display: block;
		}
	}

	&__title {
		font: 32px/32px 'RF Devi Semi Bold', sans-serif;
		color: $color-blue-dark;
		margin: 0 0 45px;

		@media screen and ($media-mobile) {
			font: 24px/24px 'RF Devi Semi Bold', sans-serif;
			margin: 0 12px 22px;
		}
	}

	&__list {
		display: flex;
		flex-flow: row wrap;
		margin: 0 (-$grid-gutter-half);
		padding: 0;
		list-style: none;

		@media screen and ($media-mobile) {
			flex-flow: row nowrap;
			overflow: auto;
			scroll-snap-type: x mandatory;
			margin: 0 (-$grid-gutter-half) 16px;
			padding: 0 20px;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__item {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(1 * 100%, 3);
		margin: 0 0 24px;

		@media screen and ($media-tablet) {
			flex: 0 0 math.div(1 * 100%, 2);
		}

		@media screen and ($media-mobile) {
			flex: 0 0 320px;
			margin: 0;
			scroll-snap-align: center;
			scroll-snap-stop: always;
			padding: 0 4px;
		}
	}

	&__box {
		width: 100%;
		padding-bottom: math.div(256 * 100%, 310);
		border-radius: 16px;
		position: relative;
		overflow: hidden;
		background: rgba($color-light, .9);

		h2 {
			font: 24px/32px 'RF Devi Semi Bold', sans-serif;
			position: absolute;
			top: 0;
			left: 0;
			padding: 22px 32px;
			margin: 0;
			@media screen and ($media-mobile) {
				padding: 16px;
			}
		}

		.abs-link {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			z-index: 1;
		}
	}
}

.ps {
  height: 60vh;

  @media screen and ($media-mobile) {
    height: auto;
  }

  &__rail-y {
    background-color: transparent !important;
    opacity: 1 !important;
  }

  &__thumb-y {
    box-sizing: border-box;
    background: transparent !important;
    border: 2px solid $color-grey;
    width: 16px !important;
    border-radius: 16px;
    opacity: 1;
    min-height: 32px;
  }
}
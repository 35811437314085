
.popup {
    align-items: center;
    background: hsla(0, 0%, 94%, .98);
    border-radius: 16px;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: calc(100vh - 20px);
    position: fixed;
    right: 5px;
    top: 5px;
    left: 5px;
    bottom: 5px;
    z-index: 999;


  &__close {
    background: transparent url(../assets/images/close-dark.svg) center / cover
      no-repeat;
    display: block;
    height: 40px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 40px;
    cursor: pointer;
  }

  &__body {
    padding: 115px 20px 0;
    width: 100%;
    margin: 0;
    height: 100%;
  }

  &__content {
    overflow: auto;
    height: 80%;

    h2 {
      margin-bottom: 10px;
    }
  }
}

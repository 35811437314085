@font-face {
  font-family: 'RF Devi Black';
  src: url(assets/fonts/rfdewi-black.woff2) format('woff2'),
  url(assets/fonts/rfdewi-black.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Ultra Bold';
  src: url(assets/fonts/rfdewi-ultrabold.woff2) format('woff2'),
  url(assets/fonts/rfdewi-ultrabold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Semi Bold';
  src: url(assets/fonts/rfdewi-semibold.woff2) format('woff2'),
  url(assets/fonts/rfdewi-semibold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Regular';
  src: url(assets/fonts/rfdewi-regular.woff2) format('woff2'),
  url(assets/fonts/rfdewi-regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Bold';
  src: url(assets/fonts/rfdewi-bold.woff2) format('woff2'),
  url(assets/fonts/rfdewi-bold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Condensed Bold';
  src: url(assets/fonts/rfdewicondensed-bold-webfont2.woff2) format('woff2'),
  url(assets/fonts/rfdewicondensed-bold-webfont2.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Condensed Regular';
  src: url(assets/fonts/rfdewi-condensed-regular.woff2) format('woff2'),
  url(assets/fonts/rfdewi-condensed-regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Condensed Semi Bold';
  src: url(assets/fonts/rfdewi-condensed-semibold.woff2) format('woff2'),
  url(assets/fonts/rfdewi-condensed-semibold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Condensed Ultra Bold';
  src: url(assets/fonts/rfdewi-condensed-ultrabold.woff2) format('woff2'),
  url(assets/fonts/rfdewi-condensed-ultrabold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RF Devi Expanded Ultra Bold';
  src: url(assets/fonts/rfdewi-expanded-ultrabold.woff2) format('woff2'),
  url(assets/fonts/rfdewi-expanded-ultrabold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GolosText Regular';
  src: url(assets/fonts/GolosText-Regular.woff2) format('woff2'),
  url(assets/fonts/GolosText-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@use "sass:math";

// Colors

$color-black: #000000;
$color-white: #FFFFFF;

$color-grey: #2D3B57;
$color-red: #D80000;
$color-blue: #2A73FF;
$color-light: #F0F0F0;

$color-blue-dark: #323C46;

// Grid
$grid-gutter-full: 24px;
$grid-gutter-half: math.div($grid-gutter-full, 2);
$grid-gutter-page: 64px - $grid-gutter-half;

// Breakpoints

$media-desktop: 'max-width: 1280px';
$media-tablet: 'max-width: 1024px';
$media-mobile: 'max-width: 768px';
$media-xmobile: 'max-width: 360px';

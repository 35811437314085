
@import "assets/scss";

.app {
	position: relative;
	max-width: 1440px;
	margin: 0 auto;

	&:before {
		content: '';
		position: fixed;
		z-index: 1;
		bottom: 10%;
		left: 0;
		width: 200%;
		height: 0;
		padding: 0 0 100%;
		background: transparent url(./assets/images/background.jpg) 0 0 / cover no-repeat;

		@media screen and ($media-mobile) {
			bottom: 20%;
		}
	}

	&--home {
		&:before {
			transform: translate(0, 50%);
			transition: transform .5s ease 0s;
		}
	}
	&--page {
		&:before {
			transform: translate(-50%, 0);
			transition: transform .5s ease 0s;

			@media screen and ($media-mobile) {
				transform: translate(0, 50%);
			}
		}
	}

	&__control {
		position: relative;
		overflow: hidden;
		z-index: 2;

		&--active {
			max-height: 3000px;
			transition: max-height .5s ease 0s;
		}

		&--hidden {
			max-height: 32px;
			transition: max-height .5s ease 0s;

			@media screen and ($media-mobile) {
				max-height: 3000px;
			}
		}
	}

	&__content {
		position: relative;
		overflow: hidden;
		z-index: 2;

		&--active {
			opacity: 1;
			transform: translateY(0);
			transition: opacity .5s ease .25s;

			@media screen and ($media-mobile) {
				.article--privacy,
				.article--rules {
					display: none;
				}

			}
		}

		&--hidden {
			opacity: 0;
			transform: translateY(20%);
			transition: opacity .5s ease 0s;

			@media screen and ($media-mobile) {
				opacity: 1;
				transform: translateY(0);

				.article--privacy, 
				.article--rules {
					display: none;
				}
			}
		}
	}

	&__wrapper {
		.app__control--active & {
			transform: translateY(0);
			transition: transform .5s ease 0s;
		}

		.app__control--hidden & {
			transform: translateY(-97.5%);
			transition: transform .5s ease 0s;

			@media screen and ($media-mobile) {
				transform: translateY(0);
			}
		}
	}

	.rules-link-block {
		position: relative;
		text-align: center;
		text-decoration: underline;
		z-index: 1;
		padding: 0 20px 20px;
    display: flex;

    .control__menu {
      @media screen and ($media-desktop) {
        width: 100%;
      }
    }

		a {
			font-family: 'GolosText Regular';
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			color: #2d3b57;
		}

	}
}

 .app__content--active + .rules-link-block {
 	display: none;
 }

.no-scroll {
	overflow: hidden;
}

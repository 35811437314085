
@use "sass:math";
@import "../assets/scss/config";

.questions-html {
	margin: 0 (-$grid-gutter-half);

	@media screen and ($media-mobile) {
		margin: 0;
	}

	&__wrap {
		width: math.div(8 * 100%, 9);
		margin: 0 math.div(8 * 100%, 9) 0 0;

		@media screen and ($media-mobile) {
			width: auto;
			margin: 0;
		}
	}

	&__holder {
		@media screen and ($media-mobile) {
			max-height: 30vh;
			overflow: hidden;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				height: 50%;
				width: 100%;
				background: linear-gradient(180deg, rgba($color-light, 0) 0%, rgba($color-light, 1) 100%);
			}

			&--showing-all {
				max-height: none;

				&:after {
					content: none;
				}
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;

		@media screen and ($media-mobile) {
			margin: 0 16px;
		}
	}

	&__item {
		border-bottom: 2px solid $color-grey;
		padding: 0 0 16px;
		margin: 0 math.div(1 * 100%, 8) 0 $grid-gutter-half;

		display: flex;
		flex-flow: column nowrap;

		@media screen and ($media-mobile) {
			margin: 0;
		}

		&:last-child {
			border-bottom: 0;
		}
	}

	&__name {
		font: 20px/24px 'RF Devi Semi Bold', sans-serif;
		letter-spacing: -0.01em;
		padding: 0 60px 0 0;
		margin: 16px 0 0;
		position: relative;
		cursor: pointer;

		@media screen and ($media-mobile) {
			font: 16px/24px 'RF Devi Regular', sans-serif;
			order: 1;
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			height: 20px;
			width: 20px;
			background: transparent url(../assets/images/expand.svg) center / cover no-repeat;
			transform: translateY(-50%);
			cursor: pointer;

			.questions-html__item--expanded & {
				background: transparent url(../assets/images/collapse.svg) center / cover no-repeat;
			}
		}
	}

	&__place {
		font: 18px/24px 'RF Devi Semi Bold', sans-serif;
		opacity: .5;
		margin: 0;
		cursor: pointer;

		@media screen and ($media-mobile) {
			font: 16px/24px 'RF Devi Regular', sans-serif;
			order: 2;
		}
	}

	&__content {
		font: 20px/28px 'RF Devi Semi Bold', sans-serif;
		margin: 32px 0 0;

		p {
			margin: 0 0 12px;
		}

		@media screen and ($media-mobile) {
			font: 16px/24px 'RF Devi Semi Bold', sans-serif;
			order: 3;
		}
	}

	&__player {
		margin: 32px 0 0;
	}

	&__preview {
		position: relative;
		width: 100%;
		padding-bottom: 56.25%;

		img, iframe {
			border: 0;
			outline: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.question-content-enter-active,
	.question-content-leave-active {
		max-height: 2000px;
		transition: all 500ms ease 0ms;
	}

	.question-content-enter-from,
	.question-content-leave-to {
		max-height: 0;
		opacity: 0;
	}
}


@use "sass:math";
@import "../assets/scss/config";

.header {
	box-sizing: border-box;
	padding: 52px 0 0 0;
	margin: 0 $grid-gutter-page 84px;
	display: flex;
	flex-flow: row nowrap;
	position: relative;
	z-index: 2;

	@media screen and ($media-mobile) {
		flex-flow: row wrap;
		padding: 32px 0 36px;
		margin: 0 4px 150px;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		right: $grid-gutter-half;
		width: calc(#{math.div(9 * 100%, 12)} - #{$grid-gutter-full});
		height: 2px;
		background: $color-grey;

		//@media screen and ($media-desktop) {
		//	width: calc(#{math.div(12 * 100%, 12)} - #{$grid-gutter-full});
		//}
    //
		@media screen and ($media-mobile) {
			right: 12px;
			width: calc(100% - 24px);
		}
	}

	&:before {
		content: '';
		position: absolute;
		bottom: -84px;
		left: calc(#{math.div(3 * 100%, 12)} + #{$grid-gutter-half});
		height: 40px;
		width: 40px;
		background: transparent url(../assets/images/coat-of-arms-grey.svg) center / cover no-repeat;

		//@media screen and ($media-desktop) {
		//	left: calc(#{math.div(0 * 100%, 12)} + #{$grid-gutter-half});
		//}
    //
		@media screen and ($media-mobile) {
			bottom: -64px;
			left: 12px;
		}
	}

  &__privacy {
    position: absolute;
    right: 0;
    bottom: -84px;
    font: 21px/21px 'GolosText Regular', sans-serif;
    box-sizing: border-box;
    padding: 0 $grid-gutter-half 0 52px;
    width: math.div(3 * 100%, 12);
    min-height: 40px;

    @media screen and ($media-desktop) {
      width: math.div(4 * 100%, 12);
    }

    @media screen and ($media-tablet) {
      width: math.div(6 * 100%, 12);
      font: 18px/18px 'GolosText Regular', sans-serif;
    }

    @media screen and ($media-mobile) {
      bottom: -130px;
      right: auto;
      left: 12px;
      width: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 36px;
      height: 40px;
      display: block;
      background: transparent url(../assets/images/privacy.svg) no-repeat;
      background-size: cover;
    }
    a {
      color: $color-black;
    }
  }

	&__logo {
		box-sizing: border-box;

    padding: 0 $grid-gutter-half;
    flex: 0 0 math.div(3 * 100%, 12);

		//@media screen and ($media-desktop) {
		//	flex: 0 0 math.div(1 * 100%, 12);
		//	margin: 0 math.div(1 * 100%, 12) auto 0;
		//}
    //
		@media screen and ($media-mobile) {
			flex: 0 0 100%;
			margin: 0 0 24px;
		}

		&:before {
			content: '';
			display: block;
			height: 151px;
			width: 151px;
			background: transparent url(../assets/images/putin.jpg) center / 180% no-repeat;
			border-radius: 8px;
			overflow: hidden;
      background-size: contain;

			@media screen and ($media-mobile) {
				width: 56px;
				height: 56px;
				padding-bottom: 0;
			}
		}
	}

	&__title {
		box-sizing: border-box;

    padding: 0 $grid-gutter-half;
    flex: 0 0 math.div(1.5 * 100%, 12);
    margin: 0 calc(math.div(1.5 * 100%, 12) - 30px) auto 0;

		font: 20px/24px 'RF Devi Semi Bold', sans-serif;

		//@media screen and ($media-desktop) {
		//	flex: 0 0 math.div(3 * 100%, 12);
		//	margin: 0 math.div(1 * 100%, 12) auto 0;
		//}
    //
		@media screen and ($media-mobile) {
			flex: 0 0 50%;
			margin: 0;
		}

    @media screen and ($media-xmobile) {
      flex: 0 0 100%;
    }

    span {
      box-sizing: border-box;
      border: 2px solid $color-black;
      display: block;
      padding: 16px 20px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 16px;
        right: -4px;
        bottom: 16px;
        left: 100%;
        background: $color-white;
      }

    }

	}

	&__date {
		box-sizing: border-box;
    padding: 0 $grid-gutter-half;
    flex: 0 0 math.div(3 * 100%, 12);

		font: 80px/80px 'GolosText Regular', sans-serif;
    letter-spacing: -0.05em;

		//@media screen and ($media-desktop) {
		//	flex: 0 0 math.div(1 * 100%, 12);
		//	margin: 0 math.div(1 * 100%, 12) auto 0;
		//}
    //
		@media screen and ($media-mobile) {
			flex: auto;
			margin: 0;
      font: 56px/56px 'GolosText Regular', sans-serif;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
		}

    @media screen and ($media-xmobile) {
      width: 100%;
      display: block;
      text-align: center;
      margin: 24px 0 0 0;
    }

	}

	&__link {
		box-sizing: border-box;
		width: 100%;
		padding: 22px 40px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		background: $color-grey;
		text-decoration: none;
		border-radius: 16px;
		margin: -12px 0 0;


		&:before {
			content: '';
			width: 28px;
			height: 34px;
			flex: 0 0 28px;
			background: transparent url(../assets/images/android-white.svg) center / contain no-repeat;
		}

		span {
			font: 16px/20px 'RF Devi Semi Bold', sans-serif;
			color: $color-white;
			margin: auto 0 auto 24px;
		}
	}

	&__file {
		position: absolute;
		bottom: -20px;
		left: 0;
		right: 0;
		text-align: center;
		font: 14px/14px 'RF Devi', sans-serif;
		color: #2D3B57;
		opacity: 0.5;
	}
}

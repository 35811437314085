
@import "../assets/scss/config";

.video-html {

	border-radius: 0 16px 16px 0;
	overflow: hidden;

	@media screen and ($media-mobile) {
		border-radius: 8px;
		margin: 0 16px;
	}

	&__player {
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		background: transparent;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border: 0;
			outline: 0;
		}
	}
}
